import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

jQuery(document).ready(function ($) {
	consoleMessage: {
		console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
	}

	modernizer: {
		if (!Modernizr.objectfit) {
			$('.object-fit').each(function () {
				var $container = $(this),
					imgUrl = $container.find('img').prop('src');
				if (imgUrl) {
					$container
						.css('backgroundImage', 'url(' + imgUrl + ')')
						.addClass('not-compatible');
				}
			});
		}
	}

	fixedheader: {
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 150) {
					$('.top-nav').addClass('fixed-header');
				}
				if ($(window).scrollTop() >= 160) {
					$('.top-nav').addClass("transform-none");
				}
				else {
					$('.top-nav').removeClass('fixed-header');
					$('.top-nav').removeClass("transform-none");
				}
			});
		});
	}

	// Gsap Animations : 

	function loadingAnimations() {

		$('.js-animate-block').each(function () {
			let trigger = $(this);

			let navFade = $(this).find('.js-nav-fade');
			let slideLeft = $(this).find($('.js-slide-left'));
			let slideRight = $(this).find($('.js-slide-right'));
			let slideUp = $(this).find($('.js-slide-up'));
			let slideDown = $(this).find($('.js-slide-down'));
			let fadeIn = $(this).find($('.js-fade-in'));
			let bannerSlideUp = $(this).find($('.js-slide-up-banner'));
			let squiggles = $(this).find('.js-squiggle');
			let squigglesLeft = $(this).find('.js-squiggle-left');
			let listItems = $(this).find('.js-list-item');


			var tl = gsap.timeline({
				// ease: "power3.inOut",
				scrollTrigger: {
					trigger: trigger,
					markers: false,
					start: "top center+=200px",
					scrub: false,
					ease: "power2.out"
				}
			});



			if (fadeIn.length) {
				tl.from(fadeIn, {
					stagger: 0.4,
					opacity: 0,
					duration: 1.6,
				}, "<+0.7")
			}
			if (navFade.length) {
				tl.from(navFade, {
					opacity: 0,
					duration: 2.6,
				}, "<+0.5")
			}
			if (bannerSlideUp.length) {
				tl.from(bannerSlideUp, {
					y: "100%",
					stagger: 0.2,
					// delay: 0.5,
					opacity: 0,
					duration: 1.2,
				}, "<+0.2")
			}
			if (slideDown.length) {
				tl.from(slideDown, {
					y: "-100%",
					stagger: 0.4,
					opacity: 0,
					duration: 1.2,
				}, "<+0.7")
			}
			if (slideLeft.length) {
				tl.from(slideLeft, {
					x: "100%",
					stagger: 0.4,
					opacity: 0,
					duration: 1.2,
				}, "<+0.7")
			}
			if (slideRight.length) {
				tl.from(slideRight, {
					x: "-100%",
					stagger: 0.4,
					opacity: 0,
					duration: 1.2,
				}, "<")
			}
			if (slideUp.length) {
				tl.from(slideUp, {
					y: "100%",
					stagger: 0.4,
					// delay: 0.5,
					opacity: 0,
					duration: 1.2,
				}, "<+0.7")
			}
			if (squiggles.length) {
				tl.from(squiggles, {
					stagger: 0.2,
					opacity: 0,
					duration: 1.2,
				}, "<+1")
			}
			if (squigglesLeft.length) {
				tl.from(squigglesLeft, {
					x: "100%",
					stagger: 0.4,
					opacity: 0,
					duration: 1.2,
				}, ">")
			}
			if (listItems.length) {
				tl.from(listItems, {
					y: "100%",
					stagger: 0.9,
					// delay: 0.5,
					opacity: 0,
					duration: 1.2,
				}, "<+0.7")
			}

		});
	}
	loadingAnimations();

	menuLinks: {
		$('.menu-item').each(function () {
			$(this).find($('a')).addClass('scroll-to');
		})
	}

	buttonHeader: {
		// var i = 1;
		// var length = $('.desktop-menu .menu-item').length;
		// $('.desktop-menu .menu-item').each(function () {
		// 	if (i == length - 1) {
		// 		$(this).find('a').addClass('button button--primary');
		// 	} else if (i == length) {
		// 		$(this).find('a').addClass('button button--secondary');
		// 	}
		// 	i++;
		// });

		// var j = 1;
		// var mobLength = $('.mobile-menu .menu-item').length;
		// $('.mobile-menu .menu-item').each(function () {
		// 	if (i == mobLength - 1) {
		// 		$(this).find('a').addClass('button button--secondary');
		// 	} else if (i == mobLength) {
		// 		$(this).find('a').addClass('button button--secondary');
		// 	}
		// 	i++;
		// });
	}

	mobileMenu: {
		$('.top-nav .js-menu-trigger').on('click', function (e) {
			$('.site-menu').toggleClass('open');
			$('.hamburger--button').toggleClass('open');

			//click outside site-menu
			let menu = $('.site-menu');
			let hamburger = $('.js-menu-trigger');
			let menuLink = $('.mobile-menu .menu-item a');

			if (menu.hasClass('open')) {
				$(document.body).on('click', function (e) {

					if (menuLink.is(e.target)) {
						//console.log('NOT the menu');

						$('.site-menu').removeClass('open');
						$('.hamburger--button').removeClass('open');

						$(document.body).off('click');
					}

				});
			}
		})
	}

	search: {
		$('.search-icon').on('click', function (e) {
			$('.searchform').toggleClass('search-open');
			$(this).toggleClass('submit-zindex');
			$('.select-lang').removeClass('transform-height');
			$('#menu-main-menu-top, .lang_dropdown').fadeOut();
			$("#s").focus();
			setTimeout(function () {
				$('.close-search').fadeIn();
			}, 300);
		});
		$('.close-search').on('click', function (e) {
			$(this).fadeOut();
			$('.searchform').removeClass('search-open');
			$('.search-icon').removeClass('submit-zindex');
			$('#menu-main-menu-top, .lang_dropdown').fadeIn();
		});
	}



	menu: {
		// Open submenu on click
		$('.top-nav .menu-item-has-children > a').on('click', function (e) {
			e.preventDefault();

			$('.menu-item-has-children').removeClass('sub-menu-open'); // reset
			$('.sub-menu-wrap').removeClass('sub-menu-open');          // reset

			$(this).closest('.menu-item-has-children').toggleClass('sub-menu-open');
			$(this).next('.sub-menu-wrap').toggleClass('sub-menu-open');
		});
		// Open menu with tabs - accessibility
		$('.top-nav .sub-menu-wrap a').on('focusin', function () {
			$(this).closest('.menu-item-has-children').addClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').addClass('sub-menu-open');

			console.log($(this));
		});
		$('.top-nav .sub-menu-wrap a').on('focusout', function () {
			$(this).closest('.menu-item-has-children').removeClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').removeClass('sub-menu-open');
		});
		// Close on click outside.
		$(document).on('click', function (e) {
			if (!$(e.target).parent().hasClass('menu-item') && $('.sub-menu-wrap').has(e.target).length === 0) {
				$('.menu-item-has-children').removeClass('sub-menu-open');
				$('.sub-menu-wrap').removeClass('sub-menu-open');
			}
		});
	}

	anchorlinks: {
		$(document).on('click', '.scroll-to', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		});
	}

	externallinks: {
		$('a[href^="mailto:"]').each(function () {
			$(this).addClass('email-link');
		});

		$('a:not(.email-link):not([class^="magnific-"])').each(function () {
			var a = new RegExp('/' + window.location.host + '/');

			if (!a.test(this.href)) {
				$(this).click(function (event) {
					event.preventDefault();
					window.open(this.href, '_blank');
				});
			}
		});
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}

	slider: {
		$('.full-width-slider').slick({
			dots: true,
			arrows: false,
			fade: true,
			autoplay: true,
			autoplaySpeed: 3500,
			speed: 1200,
		});


		/**
		 * Gallery slider with thumbnails navigation.
		 */
		$('.gallery-slider').each(function (index) {
			var status = $(this).find('.gallery-slider__full-nav .pages');
			var slickElement = $(this).find('.gallery-slider__full');
			var slickElementThumb = $(this).find('.gallery-slider__thumb');

			slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
				//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
				var i = (currentSlide ? currentSlide : 0) + 1;
				status.text(i + ' of ' + slick.slideCount);
			});

			slickElement.slick({
				dots: false,
				arrows: true,
				fade: true,
				asNavFor: $(this).find('.gallery-slider__thumb'),
				appendArrows: $(this).find('.gallery-slider__full-nav'),
			});

			slickElementThumb.slick({
				dots: false,
				arrows: true,
				slidesToShow: 4,
				// centerMode: true,
				focusOnSelect: true,
				asNavFor: $(this).find('.gallery-slider__full'),
			});
		});

		$('.js-testimonial-slider').slick({
			dots: false,
			arrows: true,
			// fade: true,
			slidesToShow: 1,
			adaptiveHeight: true,
			infinite: true,
			focusOnSelect: true,
			// centerMode: true,
		})
	}

	magnific: {
		// IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe',
			mainClass: 'mfp-video-wrapper',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,

			iframe: {
				markup: '<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					'</div>',
				patterns: {
					youtu: {
						index: 'youtu.be',
						id: function (url) {

							// Capture everything after the hostname, excluding possible querystrings.
							var m = url.match(/^.+youtu.be\/([^?]+)/);

							if (null !== m) {
								return m[1];
							}

							return null;

						},
						// Use the captured video ID in an embed URL. 
						// Add/remove querystrings as desired.
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					youtube: {
						index: 'youtube.com/',
						id: 'v=',
						src: 'https://www.youtube.com/embed/%id%?autoplay=1'
					}
				}
			}
		});


		// INLINE
		$('.magnific-inline-popup').magnificPopup({
			type: 'inline',

			fixedContentPos: false,
			fixedBgPos: true,

			overflowY: 'auto',

			closeBtnInside: true,
			preloader: false,

			midClick: true,
			removalDelay: 300,
			mainClass: 'my-mfp-zoom-in'
		});


		// GALLERY SINGLE IMAGE
		$('.magnific-gallery-single-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-single',
			image: {
				verticalFit: true,
				titleSrc: function titleSrc(item) {
					return item.el.attr('title');
				}
			},
			gallery: {
				enabled: false
			}
		});

		// GALLERY IMAGE
		$('.magnific-gallery-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-mobile',
			image: {
				verticalFit: true,
				titleSrc: function (item) {
					return item.el.attr('title');
				}
			},
			gallery: {
				enabled: true
			}
		});
	}

	accordion: {
		if ($('.block--accordion').length) {

			$('.accordion__content--wrapper').css('display', 'none'); // All items closed.

			$('.block--accordion').find('.js-accordion-trigger').on('click', function () {

				// Close only the items in this accordion.
				$(this).parent().siblings().find('.js-accordion-trigger').next().slideUp('fast'); // reset
				$(this).parent().siblings().find('.js-accordion-trigger').removeClass('open');    // reset

				// Close items in all accordions on the page.
				//$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
				//$('.js-accordion-trigger').not(this).removeClass('open');    // reset

				if ($(this).hasClass('open')) {
					$(this).next().slideUp('fast');
					$(this).removeClass('open');
				} else {
					$(this).next().slideDown('fast');
					$(this).addClass('open');
				}

			});
		}
	}

	/* tabs: {
		const tabs = document.querySelectorAll('[role="tab"]');
		const tabList = document.querySelector('[role="tablist"]');

		if ( tabs ) {

			// Add a click event handler to each tab
			tabs.forEach(tab => {
				tab.addEventListener("click", changeTabs);
			});

			// Enable arrow navigation between tabs in the tab list
			let tabFocus = 0;

			if ( tabList ) {
				tabList.addEventListener("keydown", e => {
					// Move right
					if (e.keyCode === 39 || e.keyCode === 37) {
					tabs[tabFocus].setAttribute("tabindex", -1);
					if (e.keyCode === 39) {
						tabFocus++;
						// If we're at the end, go to the start
						if (tabFocus >= tabs.length) {
						tabFocus = 0;
						}
						// Move left
					} else if (e.keyCode === 37) {
						tabFocus--;
						// If we're at the start, move to the end
						if (tabFocus < 0) {
						tabFocus = tabs.length - 1;
						}
					}
	
					tabs[tabFocus].setAttribute("tabindex", 0);
					tabs[tabFocus].focus();
					}
				});
			}

			function changeTabs(e) {
				console.info('tabs clicked');
				const target = e.target;
				const parent = target.parentNode;
				const grandparent = parent.closest('.tabs');


					console.info(grandparent);
				// Remove all current selected tabs
				parent
					.querySelectorAll('[aria-selected="true"]')
					.forEach(t => t.setAttribute("aria-selected", false));

				// Set this tab as selected
				target.setAttribute("aria-selected", true);

				// Hide all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach(p => p.setAttribute("hidden", true));

				// Show the selected panel
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.removeAttribute("hidden");
			}

		}
	} */

	loadingAnimations: {

		//gsap 

		// function loadingAnimations() {


		// 		slideUpArr.forEach(slideUp => {
		// 			tl.from(slideUp, {
		// 				y: '100%',
		// 				opacity: 0,
		// 				duration: 0.4,
		// 			},1);
		// 		});
		// 		fadeInArr.forEach(fadeIn => {
		// 			tl.from(fadeIn, {
		// 				opacity:0,					
		// 				duration: 0.9,
		// 			},1);
		// 		});

		// if(slideLeft.length) {
		// 	tl.from(slideLeft, {
		// 		duration:  1.3,
		// 		// stagger: 0.2,
		// 		x:'100%',
		// 		// opacity: 0,
		// 	}, 0.9);
		// }	

		// });
		// };


		// loadingAnimations();

		// non gsap
		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop()
			};
			viewport.bottom = viewport.top + win.height() - 80;

			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();

			return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		};

		// First load Animation
		$('.off-screen').each(function (index) {
			if ($(this).isOnScreen()) {
				$(this).removeClass('off-screen--hide');
			}
		});

		// Animation on scroll
		$(window).scroll(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}
});


